import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import { Button } from "../../Common/Button/Button";

import style from "./HeroBanner.module.css";

export const HeroBanner = () => {
    const [deviceType, setDeviceType] = useState(() => getDeviceType());
    const [imageHeight, setImageHeight] = useState(0);
    const imageRef = useRef(null);
    const divRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setDeviceType(getDeviceType());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function getDeviceType() {
        return window.innerWidth <= 600 ? "mobile" : "desktop";
    }

    useEffect(() => {
        const updateImageHeight = () => {
            if (imageRef.current) {
                setImageHeight(imageRef.current.offsetHeight);
            }
        };

        // Update height when image loads
        if (imageRef.current) {
            imageRef.current.addEventListener("load", updateImageHeight);
            updateImageHeight(); // In case image is already loaded
        }

        // Cleanup the event listener
        return () => {
            if (imageRef.current) {
                imageRef.current.removeEventListener("load", updateImageHeight);
            }
        };
    }, []);

    useEffect(() => {
        // Apply the image height to the div
        if (divRef.current) {
            divRef.current.style.height = `${imageHeight}px`;
        }
    }, [imageHeight]);

    const onVideoLoad = () => {
        if (imageRef.current) imageRef.current.remove();
    };
    const desktopVideo = () => {
        return (
            <>
                <div className={style.videoWrapper} ref={divRef}>
                    <img
                        ref={imageRef}
                        src={"/hero-banner-img-desktop.webp"}
                        className={`${style.desktop} ${style.video} ${style.videoBackground}`}
                        alt='video placeholder'
                        style={{ width: "100%", height: "auto" }}
                    />

                    <ReactPlayer
                        key={1}
                        preload='metadata'
                        muted
                        poster='/hero-banner-img-desktop.webp'
                        width='100%'
                        height='100%'
                        controls={false}
                        className={`${style.video} ${style.desktop}`}
                        config={{
                            file: {
                                attributes: {
                                    autopictureinpicture: "false",
                                    className: "inlinevideo",
                                    disablePictureInPicture: true,
                                    playsInline: true,
                                    controls: false,
                                },
                            },
                        }}
                        loop
                        playsInline
                        playing={true}
                        onStart={onVideoLoad}
                        url={"/hero-banner.mp4"}></ReactPlayer>
                </div>
            </>
        );
    };

    const mobileVideo = () => {
        return (
            <>
                <img
                    ref={imageRef}
                    src={"/hero-banner-img-mobile.webp"}
                    className={`${style.desktop} ${style.video} ${style.videoBackground}`}
                    alt='video placeholder'
                    style={{ width: "100%", height: "auto" }}
                />
                <ReactPlayer
                    key={2}
                    preload='metadata'
                    muted
                    poster='/hero-banner-img-mobile.webp'
                    width='100%'
                    height='100%'
                    controls={false}
                    className={`${style.video} ${style.mobile}`}
                    config={{
                        file: {
                            attributes: {
                                autopictureinpicture: "false",
                                className: "inlinevideo",
                                disablePictureInPicture: true,
                                playsInline: true,
                                controls: false,
                            },
                        },
                    }}
                    loop
                    playsInline
                    playing={true}
                    url={"/hero-banner-video-mobile.mp4"}></ReactPlayer>
            </>
        );
    };

    return (
        <>
            <div className={style.sloganSection}>
                {/* <h1 className={style.slogan}>
                    Кой каза, че историята
                    <br className={style.desktop} /> трябва да е скучна?
                </h1> */}
                {/* <h1 className={style.slogan}>
                    Изгуби себе си,
                    <br className={style.desktop} /> преоткрий София!
                </h1> */}
                <h1 className={style.slogan}>
                    Градска игра със загадки
                    <br className={style.desktop} /> из улиците на София
                </h1>
            </div>
            <div className={`${style.buttonWrapper} ${style.desktop}`}>
                <Button text={"Виж загадките"} navigate={"/catalogue"} version={"version-1"}></Button>
            </div>
            <div className={`${style.buttonWrapper} ${style.mobile}`}>
                <Button text={"Старт"} navigate={"/catalogue"} version={"version-9"}></Button>
            </div>

            {deviceType === "desktop" ? desktopVideo() : mobileVideo()}

            <svg width='0' height='0'>
                <defs>
                    <clipPath id='hero-banner-video-mask' clipPathUnits='objectBoundingBox'>
                        <path
                            d='M0,0 L0,0.95
                                C0.06,0.86 0.11,0.88 0.15,0.88
                                C0.20,0.88 0.25,0.85 0.29,0.845
                                C0.34,0.84 0.37,0.855 0.39,0.853
                                C0.41,0.853 0.43,0.835 0.46,0.835
                                C0.49,0.835 0.51,0.81 0.54,0.81
                                C0.56,0.81 0.60,0.78 0.64,0.775
                                C0.68,0.77 0.71,0.78 0.73,0.775
                                C0.76,0.77 0.79,0.75 0.84,0.74
                                C0.90,0.735 0.91,0.70 1,0.73
                                L1.1,0.7
                                L1.1,0
                                Z               
                            '></path>
                    </clipPath>
                </defs>
            </svg>
            <svg width='0' height='0'>
                <defs>
                    <clipPath id='hero-banner-video-mask-mobile' clipPathUnits='objectBoundingBox'>
                        <path
                            d='M0,0 L0,0.80
                                C0.10,0.85 0.18,0.88 0.34,0.87
                                C0.41,0.87 0.44,0.89 0.50,0.90
                                C0.56,0.90 0.65,0.95 0.75,0.94
                                C0.85,0.95 0.90,0.99 1,0.96
                                
                                L1.1,0.7
                                L1.1,0
                                Z               
                            '></path>
                        {/* <path
                            d='M0,0 L0,0.80
                                C0.10,0.75 0.18,0.88 0.34,0.85
                                C0.41,0.84 0.44,0.89 0.50,0.88
                                C0.56,0.86 0.65,0.96 0.75,0.93
                                C0.85,0.90 0.90,0.99 1,1
                                
                                L1.1,0.7
                                L1.1,0
                                Z               
                            '></path> */}
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};
