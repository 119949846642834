import { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";

import { Button } from "../../../Common/Button/Button";
import { Clock } from "./Clock/Clock";
import { Map } from "./Map/Map";
import { Popup } from "./Popup/Popup";
import Loader from "../../../Common/Loader/Loader";

import style from "./Question.module.css";

export const Question = ({ incorrectAnswer, setIncorrectAnswer, validateAnswerMutation, getHintMutation, skipQuestionMutation, event: eventData }) => {
    const [answer, setAnswer] = useState("");
    const [showMap, setShowMap] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState("");
    const [isContentVisible, setIsContentVisible] = useState(false);

    const calcPoints = useMemo(() => {
        const { used_hints_for_current_question } = eventData.event;
        const basePoints = eventData.question.points;

        if (used_hints_for_current_question === 1) return Math.round(basePoints * 0.7 * 10) / 10;
        if (used_hints_for_current_question === 2) return Math.round(basePoints * 0.5 * 10) / 10;
        return basePoints;
    }, [eventData]);

    const toggleContent = useCallback(() => {
        setIsContentVisible((prev) => !prev);
    }, []);

    const showPopupWithContent = useCallback((content) => {
        setPopupContent(content);
        setShowPopup(true);
    }, []);

    const handleHintUsage = useCallback(() => {
        const { used_hints_for_current_question, number_used_hints, riddle } = eventData.event;

        if (used_hints_for_current_question < 2 && number_used_hints < riddle.max_hints) {
            showPopupWithContent("hint");
        }
    }, [eventData, showPopupWithContent]);

    const handleValidateAnswer = useCallback(() => {
        validateAnswerMutation.mutate(answer.trim());
    }, [validateAnswerMutation, answer]);

    const handleGetHint = useCallback(() => getHintMutation.mutate(), [getHintMutation]);
    const handleSkipQuestion = useCallback(() => skipQuestionMutation.mutate(), [skipQuestionMutation]);

    return (
        <div className={style.questionSection}>
            {getHintMutation.isPending || skipQuestionMutation.isPending || validateAnswerMutation.isPending ? <Loader /> : null}
            <div className={style.questionWrapper}>
                <div className={style.topWrapper}>
                    <div className={style.logoSection}>
                        <img className={style.logo} alt='logo' src='/logo-primary-row-white.svg'></img>
                    </div>
                    <div className={style.hintButtonWrapper}>
                        <div
                            onClick={handleHintUsage}
                            className={`${style.hintButton}
                            ${eventData.event.number_used_hints >= eventData.event.riddle.max_hints ? style.disable : null}
                            `}>
                            {eventData.event.number_used_hints >= eventData.event.riddle.max_hints ? (
                                <p className={style.withoutHintsText}>
                                    нямаш <br />
                                    хинтове
                                </p>
                            ) : (
                                <p className={`${style.buttonText} ${eventData.event.used_hints_for_current_question === 2 ? style.disabledHintText : null}`}>
                                    хинт {eventData.event.used_hints_for_current_question}/2
                                </p>
                            )}
                        </div>
                        <div onClick={() => setShowMap(true)} className={style.mapButton}>
                            <p className={style.buttonText}>карта</p>
                        </div>
                    </div>
                </div>
                <Map map={eventData.event.riddle.google_map} showMap={showMap} setShowMap={setShowMap}></Map>
                <div className={style.titleWrapper}>
                    <p className={style.title}>
                        Въпрос {eventData.event.current_question}/{eventData.event.riddle.nQuestions}
                    </p>
                    <div className={style.titleUnderline}></div>
                    <div className={style.infoWrapper}>
                        <Clock startingTime={eventData.event.start_at}></Clock>
                        <p className={style.infoText}>{calcPoints} т.</p>
                    </div>
                </div>

                <div className={style.textWrapper}>
                    {eventData.question.img ? <img className={style.questionImage} src={eventData.question.img} alt='fragment'></img> : null}
                    <p className={style.yellow}>Упътванe към локацията:</p>
                    <p className={style.backgroundInfo}>{eventData.question.background}</p>

                    <br></br>

                    <p className={style.yellow}>Въпрос:</p>
                    {eventData.question.question.split("\n").map((line, index) => (
                        <p className={style.riddleDescription} key={index}>
                            {line}
                            <br />
                        </p>
                    ))}
                    {eventData.question.hint_1 ? (
                        <p className={style.hint}>
                            <span className={style.hintTitle}>Хинт 1:</span> {eventData.question.hint_1}
                        </p>
                    ) : null}

                    {eventData.question.hint_2 ? (
                        <p className={style.hint}>
                            <span className={style.hintTitle}>Хинт 2: </span> {eventData.question.hint_2}
                        </p>
                    ) : null}
                </div>

                <div className={style.inputWrapper}>
                    <input
                        className={`${style.questionInput} ${incorrectAnswer ? style.incorrectAnswer : null}`}
                        name='question'
                        placeholder='Въведи отговор тук...'
                        value={answer}
                        onFocus={() => setIncorrectAnswer(false)}
                        onChange={(e) => setAnswer(e.target.value)}></input>
                    {incorrectAnswer ? <p className={style.incorrectAnswerText}>Грешен отговор. Пробвай отново.</p> : null}
                </div>
                <div className={`${style.adviceWrapper} ${isContentVisible ? style.expanded : null}`}>
                    <p className={style.show} onClick={toggleContent}>
                        {isContentVisible ? "Скрий съветите" : `Натисни тук за съвети`}
                    </p>
                    <p className={style.riddleDescription}>1: Няма да намерите конкретните отговори в интернет, но при необходимост може да го ползвате за помощ.</p>
                    <p className={style.riddleDescription}>2: Не излизайте от очертанията на картата.</p>
                    <p className={style.riddleDescription}>3: Разстоянието между загадките не е твърде голямо, спрямо общото за играта.</p>
                    <p className={style.riddleDescription}>4: Може да съдите за трудността на въпроса по точките, които дава.</p>
                    <p className={style.riddleDescription}>5: Играйте отборно, движете се заедно и обсъждайте.</p>
                    <p className={style.riddleDescription}>6: Отговорът е максимум три думи или е число.</p>
                    <p className={style.riddleDescription}>7: Може да отворите играта на няколко устройства, но въвеждането следва да става само от едно от тях.</p>
                </div>
                <div className={style.buttonWrapper}>
                    <Button onClick={() => showPopupWithContent("skip")} version={"version-5"} text='Пропусни'></Button>
                    <Button onClick={handleValidateAnswer} version={"version-4"} text='Провери'></Button>
                </div>
            </div>
            <Popup
                eventData={eventData}
                points={eventData.question.points}
                popupContent={popupContent}
                getHint={handleGetHint}
                skipQuestion={handleSkipQuestion}
                showPopup={showPopup}
                setShowPopup={setShowPopup}></Popup>
        </div>
    );
};

Question.propTypes = {
    incorrectAnswer: PropTypes.bool.isRequired,
    setIncorrectAnswer: PropTypes.func.isRequired,
    validateAnswerMutation: PropTypes.object.isRequired,
    getHintMutation: PropTypes.object.isRequired,
    skipQuestionMutation: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
};
