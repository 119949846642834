import React, { useState, useEffect } from "react";
import style from "./Popup.module.css";
import { Button } from "../Button/Button";

const Popup = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        // Show the popup after 10 seconds
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 4000);

        return () => clearTimeout(timer); // Cleanup timer
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            {showPopup && (
                <div className={style.popupOverlay}>
                    <div className={style.popupContent}>
                        <button className={style.popupClose} onClick={closePopup}>
                            &times;
                        </button>
                        <h1 className={style.title}>🎄 Купи една игра, получи втората безплатно 🎄</h1>
                        <p className={style.text}>
                            Спести време за търсене на подаръци и подари незабравимо приключение на двама приятели –{" "}
                            <span className={style.bold}>на цената на една игра!</span> 🎁
                        </p>
                        <h3 className={style.subtitle}>Как работи?</h3>
                        <ol className={style.popupList}>
                            <li className={style.text}>Купи една игра от нашата селекция и избери втората, която ще получиш безплатно</li>
                            <li className={style.text}>До 1 работен ден ще получиш ваучерите за двете игри, които ще можеш да персонализираш ❤️</li>
                            <li className={style.text}>Избери дали да подариш игрите сам или ние да изненадаме приятелите ти с коледен имейл на 25 декември 🎅</li>
                        </ol>
                        <p className={style.text}>
                            Офертата е валидна до 31 декември. Не оставяй подаръците за последния момент – вземи сега и зарадвай близките си с уникално преживяване!
                            Игрите са с валидност до края на новата 2025 г.
                        </p>
                        <div className={style.btnWrapper} onClick={closePopup} width='inherit'>
                            <Button version='version-11' navigate='/catalogue' text='Купи сега'></Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Popup;
