import { Outlet, ScrollRestoration } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { CookiePopup } from "./components/TermsAndPrivacy/CookiePopup/CookiePopup.js";
import { Footer } from "./components/Footer/Footer";
import { Navbar } from "./components/Navbar/Navbar";

import style from "./root.module.css";
import Popup from "./components/Common/PopUp/PopUp.js";

const Root = () => {
    const location = useLocation();

    const [pathName, setPathName] = useState("/");

    useEffect(() => {
        setPathName(window.location.pathname);
    }, [location]);

    return (
        <div>
            {/* <div className={pathName !== "/" ? style.app : "app"}> */}
            <div>
                <Popup />
                <ScrollRestoration />
                <Navbar></Navbar>
                <Suspense fallback={<div>Loading....</div>}>
                    <Outlet></Outlet>
                </Suspense>
                {pathName === "/faq" ? <div className={style.background}></div> : null}
                {pathName === "/catalogue" ? <div className={style.background}></div> : null}
                <Footer></Footer>
                <CookiePopup />
            </div>
        </div>
    );
};

export default Root;
