import { useNavigate } from "react-router-dom";
import style from "./Button.module.css";
import { useEffect } from "react";

export const Button = (params) => {
    const navigateTo = useNavigate();
    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: "smooth" });
        window.scrollTo({ top: 0 });
    }, []);

    if (params.version === "version-1") {
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV1}`}>
                <div className={style.group}>
                    <div className={style.textV1}>{params.text ? params.text : "Виж още"}</div>
                    <img className={style.buttonArrow} alt='Vector' src='/blue-arrow.svg' />
                </div>
            </button>
        );
    } else if (params.version === "version-2") {
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV2}`}>
                <div className={style.group}>
                    <div className={style.textV2}>{params.text ? params.text : "Виж още"}</div>
                    <img className={style.buttonArrow} alt='Vector' src='/yellow-arrow.svg' />
                </div>
            </button>
        );
    } else if (params.version === "version-3") {
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV3}`}>
                <div className={style.group}>
                    <div className={style.textV3}>{params.text ? params.text : "Виж още"}</div>
                </div>
            </button>
        );
    } else if (params.version === "version-4") {
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV4}`}>
                <div className={style.group}>
                    <div className={style.textV4}>{params.text ? params.text : "Виж още"}</div>
                </div>
            </button>
        );
    } else if (params.version === "version-5") {
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV5}`}>
                <div className={style.group}>
                    <div className={style.textV5}>{params.text ? params.text : "Виж още"}</div>
                </div>
            </button>
        );
    } else if (params.version === "version-6") {
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV6}`}>
                <div className={style.group}>
                    <div className={style.textV6}>{params.text ? params.text : "Виж още"}</div>
                </div>
            </button>
        );
    } else if (params.version === "version-7") {
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV7}`}>
                <div className={style.group}>
                    <div className={style.textV7}>{params.text ? params.text : "Виж още"}</div>
                </div>
            </button>
        );
    } else if (params.version === "version-8") {
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV8}`}>
                <div className={style.group}>
                    <div className={style.textV8}>{params.text ? params.text : "Виж още"}</div>
                </div>
            </button>
        );
    } else if (params.version === "version-9") {
        // blue arrow button for mobile - same as 1 but for mobile
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV9}`}>
                <div className={style.group}>
                    <div className={style.textV9}>{params.text ? params.text : "Виж още"}</div>
                    <img className={style.buttonArrow} alt='Vector' src='/blue-arrow.svg' />
                </div>
            </button>
        );
    } else if (params.version === "version-10") {
        // yellow arrow button for mobile - same as 2 but for mobile
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV10}`}>
                <div className={style.group}>
                    <div className={style.textV10}>{params.text ? params.text : "Виж още"}</div>
                    <img className={style.buttonArrow} alt='Vector' src='/yellow-arrow.svg' />
                </div>
            </button>
        );
    } else if (params.version === "version-11") {
        return (
            <button
                onClick={() => {
                    if (params.navigate) navigateTo(params.navigate);
                    else if (params.onClick) params.onClick();
                }}
                className={`${style.button} ${style.btnV11}`}>
                <div className={style.group}>
                    <div className={style.textV3}>{params.text ? params.text : "Виж още"}</div>
                </div>
            </button>
        );
    }
};
