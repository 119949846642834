import { useEffect } from "react";
import { Button } from "./../Common/Button/Button";

import style from "./Unsubscribe.module.css";
import { useSearchParams } from "react-router-dom";
import * as newsletterService from "../../service/newsletterService";

const Unsubscribe = () => {
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");

    useEffect(() => {
        newsletterService
            .unsubscribe({ email })
            .then((res) => {})
            .catch((err) => console.log(err));
    }, [email]);
    return (
        <div className={style.notFoundWrapper}>
            <h1 className={style.title}>
                Unsubscribe <br />
            </h1>
            <p className={style.text}>Имейлът {email} няма да получава маркетингови съобщения от нас. </p>
            <div className={style.buttonWrapper}>
                <Button navigate='/' version='version-2' text='Начало'></Button>
            </div>
        </div>
    );
};

export default Unsubscribe;
