import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";

import { ActionStage } from "./ActionStage/ActionStage";
import { EndStage } from "./EndStage/EndStage";
import { StartStage } from "./StartStage/StartStage";
import * as eventService from "./../../service/eventService";
import EventReloadPage from "./EventReloadPage/EventReloadPage";
import Loader from "../Common/Loader/Loader";

export const Event = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const eventQuery = useQuery({
        queryKey: ["eventStageData", token],
        queryFn: async () => {
            const res = await eventService.getCurrentState(token)
            if (res.status === "fail" && res.message === "Не съществува евент с това ID") navigate("/404");
            return res
        },
        refetchOnWindowFocus: false,
    });

    const startEventMutation = useMutation({
        mutationFn: (teamNameInput) => eventService.startEvent(token, { team_name: teamNameInput }),
        onSuccess: (res) => {
            if (res.status === "success") {
                queryClient.invalidateQueries({ queryKey: ["eventStageData", token] });
            } else if (res.status === "fail") {
                alert(res.message);
            }
        },
        retry: 3,
        retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
        refetchOnWindowFocus: false,
    });

    const reloadEvent = () => {
        eventQuery.refetch();
    };

    if (eventQuery.isLoading) return <Loader />;

    if (eventQuery.isError || startEventMutation.isError) return <EventReloadPage reloadEvent={reloadEvent} />;

    const renderStage = () => {
        switch (eventQuery.data.currentState) {
            case "start":
                return <StartStage startEventMutation={startEventMutation} eventData={eventQuery.data} />;
            case "action":
                return <ActionStage reloadEvent={reloadEvent} setEvent={reloadEvent} event={eventQuery.data} setReload={reloadEvent} />;
            case "end":
                return <EndStage eventData={eventQuery.data} />;
            default:
                return null;
        }
    };

    return (
        <>
            {eventQuery.isLoading || eventQuery.isFetching || startEventMutation.isPending ? <Loader /> : null}
            <div>{renderStage()}</div>
        </>
    );
};

export default Event;
